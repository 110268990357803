<template>
    <div class="job-assignment">
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
        <calendar-day-status v-bind="$props" />
        </div>

    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import CalendarDayStatus from '@/components/CalendarDayStatus.vue'

export default {
    components: {
        Navbar,
        CalendarDayStatus,
    },
}
</script>

<style>

</style>
