<template>
    <div>
        <v-calendar
            class="mb-3"
            ref="calendar"
            :attributes="computedAttributes"
            :month="5"
            :columns="12" :rows="1"
            :min-date="minDate"
            :max-date="maxDate"
            mode="date"
            :first-day-of-week="2"
            @dayclick='dayClicked'
        
        />

    <div class="row" v-if="hasChanges">
        <div class="col-md-6">
            <div class="btn btn-primary" @click="saveChanges">Sačuvaj izmene</div>
        </div>
        <div class="col-md-6">
            <ul>
                <li v-for="status, val in selectedDates" :key="val">
                    <template v-if="status"><font-awesome-icon icon="plus" /></template>
                    <template v-else><font-awesome-icon icon="times" /></template>
                     {{val}}
                </li>
            </ul>
        </div>
    </div>

    </div>

</template>


<script>
    import UtilService from '@/service/UtilService.js';
    import CalendarService from '@/service/CalendarService.js';
    import Vue from 'vue';
    import {mapState} from 'vuex'

    export default {

        data() {
            return {
                minDate: new Date("2020-01-01"),
                maxDate: UtilService.getMoment().add(2,'year').toDate(),
                attributes: [
                    {
                        // key: 'today',
                        highlight: true,
                        dates: new Date(),
                    },

                ],

                // dataSource: [
                //     {
                //         id: 6,
                //         name: 'F8 2015',
                //         startDate: new Date(2021, 2, 25),
                //         endDate: new Date(2021, 2, 25),
                //     },
                // ],


                calendarData: [],
                calendarWokringData: [],

                selectedDates: {

                }
            }
        },

        computed: {
            ...mapState([
                'user',
            ]),

            computedAttributes() {
                let attributes = [
                    {
                    key: 'today',
                    bar: true,
                    dates: new Date(),
                    },
                ];


                console.log(this.calendarWokringData);
                const self = this;
                let disabled_dates = this.calendarData.filter(x => self.selectedDates[x.date] !== false);
                disabled_dates = disabled_dates.filter(d => {
                    return !self.calendarWokringData.some(w => w.date === d.date && w.working === true);
                });
                
                let new_dates = []
                let removed_dates = []

                Object.entries(this.selectedDates).forEach((entry) => {
                    if (entry[1] == true) {
                        // disabled_dates.push(entry[0]);
                        new_dates.push(entry[0]);
                    } else {
                        removed_dates.push(entry[0]);
                    }
                });

                attributes.push({
                    highlight: 'gray',
                    popover: {
                        label: "Neradni dan",
                    },
                    dates: disabled_dates.map(x => x.date),
                });

                attributes.push({
                    highlight: 'gray',
                    dot: {
                        style: {
                            backgroundColor: 'black',
                        }
                    },
                    popover: {
                        label: "Neradni dan",
                    },
                    dates: new_dates,
                });

                attributes.push({
                    highlight: {
                        color: 'gray',
                        fillMode: 'outline',
                    },
                    popover: {
                        label: "Uklonjen",
                    },
                    dates: removed_dates,
                });

                attributes.push({
                    highlight: {
                        color: 'red',
                        fillMode: 'outline',
                    },
                    popover: {
                        label: "Radni dan",
                    },
                    dates: this.calendarWokringData.map(x => x.date),
                });

                return attributes;
                    
            },

            hasChanges() {
                let length = Object.keys(this.selectedDates).length !== 0
                if (length > 0){
                    this.enableClosePrompt();
                } else {
                    this.disableClosePrompt();
                }

                return length;
            }
        },

        methods: {
            dateToUTC(date) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
            },

            enableClosePrompt() {
                this.$store.dispatch('enableClosePrompt', {message: "Da li želite da izađete? Kalendar nije sačuvan!"});
            },
            disableClosePrompt() {
                this.$store.dispatch('disableClosePrompt');
            },
            
            dayClicked(attr) {
                if (this.user.role !== "ADMIN") return;


                let utcDate = this.dateToUTC(attr.date);
                let date_str = utcDate.toISOString().slice(0,10);
                

                let status = this.selectedDates[date_str];
                let isLoaded = this.calendarData.some(x => x.date == date_str); 

                if ((isLoaded && status === false) || (!isLoaded && status === true)){
                    Vue.delete(this.selectedDates, date_str)
                } else if (isLoaded && !status) {
                    Vue.set(this.selectedDates, date_str, false);
                } else {
                    Vue.set(this.selectedDates, date_str, true);
                }

            },

            async loadNonWorkingDays() {
                await CalendarService.getNonWorkingDays().then((response) => {
                    this.calendarData = response.data;
                }).catch(() => {
                    alert("Neuspesno ucitavanje kalendara")
                })
            },

            async loadUserWorkingDays() {
                let data = {
                    employee_id: this.user.id
                }

                await CalendarService.getEmployeeDayStatus(data).then((response) => {
                    this.calendarWokringData = response.data;
                }).catch(() => {
                    alert("Neuspesno ucitavanje radnih dana za korisnika")
                })
            },

            getChangesList() {
                let changes = []
                Object.entries(this.selectedDates).forEach(value => {
                    changes.push({
                        'date': value[0],
                        'deleted': !value[1]
                    });
                });
                return changes
            },

            async saveChanges() {
                let data = this.getChangesList();

                await CalendarService.updateNonWorkingDays(data).then((response) => {
                    this.calendarData = response.data;
                    this.selectedDates = {};
                    this.toast("Uspešno ste sačuvali neradne dane")
                }).catch(() => {
                    alert("Neuspesno čuvanje kalendara")
                })
            },


            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
               });
            },

        },

        created() {
            this.loadNonWorkingDays();
            if (this.user.role !== "ADMIN") this.loadUserWorkingDays();
        },

        mounted() {
            const calendar = this.$refs.calendar;
            // Moves to my birthday
            let months = new Date().getMonth();
            calendar.move(-months);
        }


    }
</script>

<style>
    .vc-container {
        width: 100%;
    }

    .vc-container .vc-pane-layout {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }
    .vc-container .vc-pane-layout .vc-pane {
        flex-basis: 21%;
    }


</style>
